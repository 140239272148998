<template>
  <v-content v-if="!success">
    <v-container fill-height>
      <v-layout align-center justify-center class="signin_layout">
        <div class="signin_box_wrapper">          
          <div class="signin_box_img"></div>
          <div class="signin_box">
            <div class="signin_header">
              <img src="@/assets/img/bici/logo.png" width="40"/>
              <div class="signin_title">
                <small>China1 | HCCP</small>
                <span>Sign In to user account</span>
              </div>
            </div>
            <div class="input_fields">
              <v-text-field 
                v-model="username" 
                name="signin" 
                label="Email" 
                type="text"
              ></v-text-field>
              <v-text-field
                v-model="password"
                outlined
                id="password"
                name="password"
                label="Password"
                type="password"
                @keyup.enter="handleSubmit()"
              ></v-text-field>
            </div>
            
            <div class="forgot"><router-link to="/Forgot">Forgot Password?</router-link></div>
            <div v-bind:style="incorrect" class="incorrect">{{errMsg}}</div>
            <div class="button_wrapper">
              <v-btn color="warning" @click="handleSubmit()">Sign-In</v-btn>
            </div>
            
            <div class="register">
              <span @click="downloadPDF" title="How to Account Register Guidance"><img src="@/assets/img/icon/help.svg"/></span>
              Click here to <router-link to="/register">Get Permission</router-link></div>
            <div class="description">
              Optimized for viewing in 
              <a href="https://www.google.com/chrome/?brand=CHBD&gclid=CjwKCAjwiMj2BRBFEiwAYfTbClJ4Iht0So5ri1TexRLdt6qK6Z_26bkFyF3nyzLsIvC_RYWydvVAuBoCgp8QAvD_BwE&gclsrc=aw.ds" target="_blank">
              Chrome
              </a>
              <br>to provide better reporting service.
            </div>     
          </div>
        </div>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import '@/assets/stylus/ui/component/_signin.styl'

import { mapState, mapActions } from 'vuex'
import __C from '../primitives/_constant_'
import ServiceRoutes from '@/mixins/service.routers.mixin'


export default {
  name: 'signin',
  mixins: [
    ServiceRoutes
  ],
  data: () => ({
    errMsg: '',
    accountService: null,
    username: '',
    password: '',
    submitted: false,
    success: false,
    incorrect: {
      visibility: 'hidden'
    },
  }),
  created() {
    this.success = false
  },
  watch: {
    username(v) {
      if (v == '') {
        this.incorrect.visibility='hidden'
      }
    },
    password(v) {
      if (v == '') {
        this.incorrect.visibility='hidden'
      }
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['status']),
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.ACCOUNT, ['signin', 'signout']),
    ...mapActions(__C.STORE_NAMESPACE.APPLICATION, ['initialize']),

    handleSubmit() {
      this.submitted = true;
      const { username, password } = this;

        this.signin({ username, password }).then(success => {
          if(!success.error) {
            this.initialize().then(navItems => {
              this.setServiceRouter(navItems)
              this.$router.replace('/', () => {})
              this.incorrect.visibility='hidden'
            })
            
          } else{
            this.incorrect.visibility='visible'
            this.errMsg = success.message
          }
        })
    },
    downloadPDF(){
      let link = document.createElement('a')
      link.href = `${__C.HOST_NAME}/resources/pdf/JGS_Account_Register_Guidance_ver1.0.pdf`
      // link.href = `${__C.HOST_NAME}/img/JGS_Account_Register_Guidance_ver1.0.pdf`
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>